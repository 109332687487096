/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        var $target = $('[data-nav-trigger]');

        $target.toggleClass('active');
        $('[data-nav]').slideToggle(300);
    });
});


/*--------------------------------------------------------------------------*
 * ヘッダー固定
 *--------------------------------------------------------------------------*/
// $(function() {
//     var $win = $(window),
//         $main = $('#container'),
//         $header = $('header'),
//         headerHeight = $header.outerHeight(),
//         headerPos = $header.offset().top,
//         fixedClass = 'is-fixed';
    
//     if ($(window).width() <= 1022) {
//         $win.on('load scroll', function() {
//             var value = $(this).scrollTop();
//             if ( value > headerPos ) {
//                 $header.addClass(fixedClass);
//                 $main.css('margin-top', headerHeight);
//             } else {
//                 $header.removeClass(fixedClass);
//                 $main.css('margin-top', '0');
//             }
//         });
//     }
//   });